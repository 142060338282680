import React, {useEffect, useCallback} from 'react';
import {useParams} from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from "react-i18next";
import { API_URL} from '../utils/api';
import { axiosWithXAuth } from "../utils/functions";
import { dataConstant, PAGES, LEVELS } from '../constants';
import { t } from 'i18next';


const withAppLoader = (AppComponent) => {

    var building_list = [];
    var cluster_list = [];

    const AppView = props => {
        const dispatch = useDispatch();
        // const {t, i18n} = useTranslation();
        let { c_code, b_code, level } = useParams();
        var { buildings} = useSelector(
            ({core, data, content}) => ({
                buildings: data.buildingList,
            })
        )

        const setCurrentPage = () => {
            if (window.location.pathname.indexOf('/mobile/alarm/kpi') > -1){
                dispatch({
                    type: dataConstant.type.CHANGE_PAGE,
                    page: PAGES.AlarmKPI,
                });
            }
            else{
                dispatch({
                    type: dataConstant.type.CHANGE_PAGE,
                    page: PAGES.AlarmRecord,
                });
            }
        }

        const setCurrentLevel = () => {
            if (window.location.pathname.indexOf('/mobile/alarm/record') > -1){
                dispatch({
                    type: dataConstant.type.CHANGE_LEVEL,
                    level: level,
                });
            }
            else{
                dispatch({
                    type: dataConstant.type.CHANGE_LEVEL,
                    level: null,
                });
            }
        }

        const fetchBuildingList = useCallback(() => {
            return new Promise((res)=>{
                axiosWithXAuth({
                    method: "post",
                    url: `${API_URL}/api/clinic/buildingList`
                })
                .then((response) => response.data)
                .then((data) => {
                    var buildingList = data.map(building => {
                        return {
                            id: building.id,
                            building_code: building.building_code,
                            enName: building.building_name_en,
                            tcName: building.building_name_tc,
                            building_type_id: building.building_type_id,
                            building_type_name: building.building_type,
                            building_type_name_tc: building.building_type_name_tc,
                            address: building.address_en,
                            address_tc: building.address_tc,
                            client: building.client,
                            lon: building.longitude ?? 0,
                            lat: building.latitude ?? 0,
                            height: building.height,
                            gfa: building.gfa,
                            completionDate: building.building_complete_date,
                            storey: building.storey,
                            imgPath: building.building_img_path,
                            region_code: building.universal_region_code,
                            region: building.region_name_en,
                            region_tc: building.region_name_tc,
                            region_id: building.region_id,
                            alarm_normal: building.ba_normal ?? 0,
                            alarm_ember: building.ba_amber ?? 0,
                            alarm_critical: building.ba_critical ?? 0,
                            alarm_fatal: building.ba_fatal ?? 0,
                            cert: building.cert_count ?? 0,
                            responsible_officer: building.responsible_officer,
                        };
                    });

                    building_list = buildingList.filter((b)=>{
                        return (b.id<1000) || (b.id>=1003);
                    });

                    dispatch({
                        type: dataConstant.type.SET_BUILDING_LIST,
                        buildingList: building_list
                    });    
                    res(); 
                })
            })

        }, [dispatch]);


        const setClusters = () =>{
            let c = [];
            let clusters = [];
            building_list.forEach((b)=>{
                if (!c.includes(b.region_code)){
                    c.push(b.region_code);
                    clusters.push({enName:b.region_code, tcName: b.region_tc});
                }
            });
            clusters.sort(function(a, b){
                if(a.enName < b.enName) { return -1; }
                if(a.enName > b.enName) { return 1; }
                return 0;
            });
            cluster_list = clusters;
            dispatch({
                type: dataConstant.type.SET_CLUSTER_LIST,
                clusterList: clusters
            });  
        }

        const processSelectCluster = () => {
            if (c_code!==undefined){
                let list = [];
                building_list.forEach((b)=>{
                    if (b.region_code===c_code){
                        list.push(b);
                    }
                });
                dispatch({
                    type: dataConstant.type.CHANGE_BUILDING,
                    building: null
                });
                dispatch({
                    type: dataConstant.type.UPDATE_BUILDING_LIST,
                    buildingList: list
                });
                cluster_list.forEach((c)=>{
                    if (c.enName===c_code){
                        dispatch({
                            type: dataConstant.type.CHANGE_CLUSTER,
                            cluster: c
                        });
                        return;
                    }
                });
                
            } 
        }

        const processSelectBuilding = () => {
            if (b_code!==undefined){
                dispatch({
                    type: dataConstant.type.CHANGE_CLUSTER,
                    cluster: null
                });
                building_list.forEach((b)=>{
                    if (b.building_code===b_code){
                        // dispatch({
                        //     type: dataConstant.type.CHANGE_BUILDING,
                        //     building: b
                        // });
                        dispatch({
                            type: dataConstant.type.UPDATE_BUILDING_LIST,
                            buildingList: [b]
                        });
                    }
                });
            }
        }

        const setDefaultSortedBuildingList = () =>{
            dispatch({
                type: dataConstant.type.UPDATE_BUILDING_LIST,
                buildingList: building_list
            }); 
        }

        const fetchData = useCallback(() => {
            setCurrentPage();
            setCurrentLevel();
            fetchBuildingList()
            .then(()=>{
                setClusters()
                if (c_code !== undefined)
                    processSelectCluster();
                else{
                    if (b_code !== undefined)
                        processSelectBuilding();
                    else
                        setDefaultSortedBuildingList();
                }
            })
        }, [fetchBuildingList]);

        useEffect(() => {
            window.onpopstate = function(event) {
                if(event && event.state) {
                    console.log("back: "+ event.state);
                    c_code = event.state.hasOwnProperty('c_code')?event.state.c_code:undefined;
                    b_code = event.state.hasOwnProperty('b_code')?event.state.b_code:undefined;
                    level = event.state.hasOwnProperty('level')?event.state.level:undefined;
                    fetchData();
                }
            }
            fetchData();
            // const timer = setInterval(() => { fetchData() }, 5 * 60 * 1000)
            // return () => clearInterval(timer);
        }, []);

        return (
            <AppComponent  {...props}/>
        )
    }

    return AppView
}

export default withAppLoader