import React, { useState,useEffect } from "react";
import {useParams} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, Button, ListGroup, Alert, Card, Tab, Tabs  } from 'react-bootstrap';
import moment from "moment";
import { FaRegBell,FaRegBuilding } from 'react-icons/fa';
import { RiAlarmLine, RiFileTextLine,RiBuildingLine } from "react-icons/ri";

import { dataConstant, LEVELS, PAGES } from '../../constants';
import { axiosWithXAuth } from "../../utils/functions";
import { API_URL} from '../../utils/api';
import DecideRemovalModal from "../components/modal/DecideRemovalModal";

const ApprovalCenter = props => {

  const {t} = useTranslation();
  const dispatch = useDispatch();

  const [hasAuthority, setHasAuthority] = useState(false);
  const [transitionStyle, setTransitionStyle] = useState({opacity: '0'});
  const [requestList, setRequestList] = useState([]);
  const [historyList, setHistoryList] = useState([]);

  const [selectedRequestObj, setSelectedRequestObj] = useState({});
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  var { buildings, sorted_buildings, selected_building, selected_cluster, level} = useSelector(
    ({core, data}) => ({
        buildings: data.buildingList,
        sorted_buildings: data.sortedBuildingList,
        selected_building: data.selectedBuilding,
        selected_cluster: data.selectedCluster,
        level: data.level
    })
  )

  useEffect(() => {
    callApi();
  }, []);

  const showSuccessMsg=(msg)=>{
    setSuccessMsg("");
    if (msg!==null)
        setSuccessMsg(msg);
    setShowSuccess(true);
    const timer = setTimeout(() => {
        setShowSuccess(false);
        setSuccessMsg("");
    }, 2000);
    return () => clearTimeout(timer);
}

const showErrorMsg=(msg)=>{
    setErrMsg("");
    if (msg!==null)
        setErrMsg(msg);
    setShowError(true);
    const timer = setTimeout(() => {
        setShowError(false);
        setErrMsg("");
    }, 5000);
    return () => clearTimeout(timer);
}

  const callApi=()=>{
    setTransitionStyle({
      opacity: '0'
    });
    axiosWithXAuth({
      method: "post",
      url: `${API_URL}/api/clinic/alarm/inbox`,
      data:{}
    })
    .then((response) => response.data)
    .then((data) => {
      if (data.length>0){
        if(data[0]["alm_right_approve"]===1)
          setHasAuthority(true);
      }
      let request_records = data.filter(el=>el.result===null);
      let history_records = data.filter(el=>el.result!==null);
      setRequestList(request_records);
      setHistoryList(history_records);
      setTransitionStyle({
        transition: 'opacity 0.3s',
        opacity: '1'
      });
    })
  }

  const rejectApi=(app_id, reason)=>{
    
    axiosWithXAuth({
        method: "post",           
        url: `${API_URL}/api/clinic/alarm/removalReject`,
        data: {app_id: app_id, reason: reason}
    })
    .then((response) => response.data)
    .then((data) => {
      showSuccessMsg();
      document.getElementById(`footer_btn_${app_id}`).style.display = "none";
      document.getElementById(`footer_reject_${app_id}`).style.display = "block";
    }).catch((error) => {
      if (error.response === undefined){
          return;
      }
      if (error.response.status === 400){
          if (error.response.data.errCode !== undefined){
              if (error.response.data.errCode !== 99){
                  showErrorMsg(error.response.data.errMsg);
                  return;
              }
          }
      }
      showErrorMsg();
    })
  }

  const approveApi=(app_id, reason)=>{

    axiosWithXAuth({
      method: "post",           
      url: `${API_URL}/api/clinic/alarm/removalApprove`,
      data: {app_id: app_id, reason: reason}
    })
    .then((response) => response.data)
    .then((data) => {
      showSuccessMsg(t("approval_center.popup.confirm_msg_a"));
      document.getElementById(`footer_btn_${app_id}`).style.display = "none";
      document.getElementById(`footer_approve_${app_id}`).style.display = "block";
    }).catch((error) => {
      if (error.response === undefined){
        return;
      }
      if (error.response.status === 400){
          if (error.response.data.errCode !== undefined){
              if (error.response.data.errCode !== 99){
                  showErrorMsg(error.response.data.errMsg);
                  return;
              }
          }
      }
      showErrorMsg();
    });
  }

  const onClickReject=(item)=>{
    setSelectedRequestObj(item);
    setShowRejectModal(true);
  }

  const onClickApprove=(item)=>{
    setSelectedRequestObj(item);
    setShowApproveModal(true);
  }

  const renderRequestTab=(list)=>{
    if (list.length<=0)
      return renderEmpty()

    return list.map((el,i)=>{
        return renderRequestItem(el,i);
      })
  }

  const renderRequestItem=(item, i)=>{
    let index = i+1;
    let app_id = item.app_id
    let bName = window.localStorage.i18nextLng==="en"? item.building_name_en: item.building_name_tc;
    let requestDt=(item.remove_dt===undefined || item.remove_dt===null)?"---":moment(item.remove_dt).format('YY-MM-DD HH:mm');
    let requestPost=item.remove_post;
    let alarmName=item.name;
    let alarmDes=item.description;
    let alarmLv=item.priority===4?"fatal":item.priority===3?"critical":item.priority===2?"alert":"";
    let onDt=(item.on_dt===undefined || item.on_dt===null)?"---":moment(item.on_dt).format('YY-MM-DD HH:mm');
    let offDt=(item.off_dt===undefined || item.off_dt===null)?"---":moment(item.off_dt).format('YY-MM-DD HH:mm');
    let isTest=item.isTest===0?false:true;
    let requestReason=item.remove_reason;
    return(
      <Card className="approval_container mb-2" key={"item"+app_id} style={transitionStyle}>
        <Card.Header className="text-center">
          {
            isTest?
            <div className="fw-bold" >- For Testing Only -</div>
            :null
          }
          <Row className="text-muted" style={{"fontSize":"small"}}>
            <Col>{t("approval_center.requested_by")}</Col>
            <Col>{t("approval_center.requested_at")}</Col>
          </Row>
          <Row>
            <Col>{requestPost}</Col>
            <Col>{requestDt}</Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {/* <Card.Title></Card.Title> */}
          <Card.Text>
            <Row><div className="fw-bold">{alarmName}  <span className={"alarm_lv "+alarmLv}>{t("alarm.level."+alarmLv)}</span></div></Row>
            <Row><div className="fw-bold" style={{"color":"rgb(3, 79, 132)"}}>{alarmDes}</div></Row>
            <Row>
              <Col xs={1}><RiAlarmLine/></Col>
              <Col>
                {onDt} - {offDt}
              </Col>
            </Row>
            <Row>
              <Col xs={1}><RiBuildingLine/></Col>
              <Col>{bName}</Col>
            </Row>
            <Row>
              <Col xs={1}><RiFileTextLine/></Col>
              <Col>{requestReason}</Col>
            </Row>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-center" id={"footer_approve_"+app_id} style={{"display":"none"}}>
          {t("approval_center.popup.result_a")}
        </Card.Footer>
        <Card.Footer className="text-center" id={"footer_reject_"+app_id} style={{"display":"none"}}>
          {t("approval_center.popup.result_r")}
        </Card.Footer>
        <Card.Footer className="text-center" id={"footer_btn_"+app_id}>
          {
            (hasAuthority)?
            <Row>
              <Col>
                <Button variant="danger" onClick={()=>{onClickReject(item)}}>{t("approval_center.btn_reject")}</Button>
              </Col>
              <Col>
                <Button variant="success" onClick={()=>{onClickApprove(item)}}>{t("approval_center.btn_approve")}</Button>
              </Col>
            </Row>
            :
            renderNoAuthority()
          }
        </Card.Footer>
      </Card>
    )
  }

  const renderHistoryTab=(list)=>{
    if (list.length<=0)
      return renderEmpty()

    return list.map((el,i)=>{
      return renderHistoryItem(el,i);
    })
  }

  const renderHistoryItem=(item, i)=>{
    let index = i+1;
    let app_id = item.app_id
    let bName = window.localStorage.i18nextLng==="en"? item.building_name_en: item.building_name_tc;
    let requestDt=(item.remove_dt===undefined || item.remove_dt===null)?"---":moment(item.remove_dt).format('YY-MM-DD HH:mm');
    let requestPost=item.remove_post;
    let alarmName=item.name;
    let alarmDes=item.description;
    let alarmLv=item.priority===4?"fatal":item.priority===3?"critical":item.priority===2?"alert":"";
    let onDt=(item.on_dt===undefined || item.on_dt===null)?"---":moment(item.on_dt).format('YY-MM-DD HH:mm');
    let offDt=(item.off_dt===undefined || item.off_dt===null)?"---":moment(item.off_dt).format('YY-MM-DD HH:mm');
    let isTest=item.isTest===0?false:true;
    let requestReason=item.remove_reason;
    let resultText=item.result===1?t("approval_center.popup.result_a"):t("approval_center.popup.result_r");
    let resultDt=(item.result_dt===undefined || item.result_dt===null)?"---":moment(item.result_dt).format('YY-MM-DD HH:mm');
    let resultPost=item.result_post;
    let resultReason=item.reason;
    let resultByLabel=item.result===1?t("approval_center.approved_by"):t("approval_center.rejected_by");
    let resultAtLabel=item.result===1?t("approval_center.approved_at"):t("approval_center.rejected_at");
    return(
      <Card className="approval_container mb-2" key={"item"+app_id}  style={transitionStyle}>
        <Card.Header className="text-center">
          {
            isTest?
            <div className="fw-bold" >- For Testing Only -</div>
            :null
          }
          <Row className="text-muted" style={{"fontSize":"small"}}>
            <Col>{t("approval_center.requested_by")}</Col>
            <Col>{t("approval_center.requested_at")}</Col>
          </Row>
          <Row>
            <Col>{requestPost}</Col>
            <Col>{requestDt}</Col>
          </Row>
        </Card.Header>
        <Card.Body>
          {/* <Card.Title></Card.Title> */}
          <Card.Text>
            <Row><div className="fw-bold">{alarmName}  <span className={"alarm_lv "+alarmLv}>{t("alarm.level."+alarmLv)}</span></div></Row>
            <Row><div className="fw-bold" style={{"color":"rgb(3, 79, 132)"}}>{alarmDes}</div></Row>
            <Row>
              <Col xs={1}><RiAlarmLine/></Col>
              <Col>
                {onDt} - {offDt}
              </Col>
            </Row>
            <Row>
              <Col xs={1}><RiBuildingLine/></Col>
              <Col>{bName}</Col>
            </Row>
            <Row>
              <Col xs={1}><RiFileTextLine/></Col>
              <Col>{requestReason}</Col>
            </Row>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="text-center" >
          <Row className="text-muted" style={{"fontSize":"small"}}>
            <Col>{resultByLabel}</Col>
            <Col>{resultAtLabel}</Col>
          </Row>
          <Row>
            <Col>{resultPost}</Col>
            <Col>{resultDt}</Col>
          </Row>
          <Row className="text-muted" style={{"fontSize":"small"}}>
            <Col>{t("approval_center.result")}</Col>
            <Col>{t("approval_center.result_reason")}</Col>
          </Row>
          <Row>
            <Col>{resultText}</Col>
            <Col>{resultReason}</Col>
          </Row>
        </Card.Footer>

      </Card>
    )
  }

  const renderContent=()=>{
    return (
      <Container className="approval_center">
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible show={showError} className="myMsgBar">
            {t("common.err_msg")}<br/>
            {errMsg}
        </Alert>
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible show={showSuccess} className="myMsgBar">
            {t("common.success_msg")}<br/>
            {successMsg}
        </Alert>
        
        <Tabs
          defaultActiveKey="application"
          id="approval_center_tab_container"
          className="myPageTab mt-2 mb-3"
          onSelect={() => callApi()}
        >
          <Tab eventKey="application" title={t("approval_center.tabs.request")} className="pb-5">
            {renderRequestTab(requestList)}
          </Tab>
          <Tab eventKey="approve_history" title={t("approval_center.tabs.history")} className="pb-5">
            {renderHistoryTab(historyList)}
          </Tab>
        </Tabs>

        <DecideRemovalModal 
            requestObj={selectedRequestObj}                 
            showModal={showRejectModal}
            setShowModal={setShowRejectModal}
            saveFn={rejectApi}
            isApprove={false}
        />

        <DecideRemovalModal 
            requestObj={selectedRequestObj}                 
            showModal={showApproveModal}
            setShowModal={setShowApproveModal}
            saveFn={approveApi}
            isApprove={true}
        />

      </Container>
    )
  }

  const renderNoAuthority=()=>{
    return (
        <Row><Col>{t("approval_center.no_authority_msg")}</Col></Row>
    )
  }

  const renderEmpty=()=>{
    return (
      <Container className="text-center mt-3">
        <Row><Col>{t("approval_center.no_record")}</Col></Row>
      </Container>
    )
  }

  return renderContent();

};
export default ApprovalCenter;
