import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from "i18next-http-backend";
import LanguageDetector  from 'i18next-browser-languagedetector';

import moment from 'moment';
import 'moment/locale/zh-hk'

import * as API from './utils/api';

moment.updateLocale('en', {
  longDateFormat : {
    LL: "DD MMMM YYYY",
  }
});

moment.updateLocale('zh-hk', {
  weekdaysShort: '星期日_星期一_星期二_星期三_星期四_星期五_星期六'.split('_'),
});

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
.init({
    backend: {
      loadPath: () => {
        return (API.PROD ? `/mobile/locales/{{lng}}/{{ns}}.json`: '/mobile/locales/{{lng}}/{{ns}}.json');
      },
    },
    fallbackLng: 'en',
    debug: true,
    interpolation: {
        escapeValue: false
    },
    react: {
      wait: true
    },
    detection: {
      order: ['cookie', 'localStorage', "navigator"],
      caches: ['localStorage', 'cookie']
    }
}, () => {
  moment.locale(i18n.language)
})

export default i18n;