const initState = {
  lang: "en",
  
};

const coreReducer = (state = initState, action) => {
  switch (action.type) {
    
    default:
      return state;
  }
};

export default coreReducer;
