import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, ListGroup, Row, Col } from 'react-bootstrap';

import { FaRegBell } from 'react-icons/fa';
import { FiClipboard } from "react-icons/fi";
import { RiMailCheckLine } from "react-icons/ri";
import { AiOutlineExperiment } from "react-icons/ai";
import { MdOutlineLogout } from "react-icons/md";

import { dataConstant, PAGES } from '../../constants';

const MenuOffcanvas = ({
    show = false,
    setShowFn = false,
}) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const handleClose = () => setShowFn(false);

    var { buildings, selected_building, sorted_buildings, clusters, selected_cluster} = useSelector(
        ({core, data, state}) => ({
            buildings: data.buildingList,
            sorted_buildings: data.sortedBuildingList,
            selected_building: data.selectedBuilding,
            clusters: data.clusterList,
            selected_cluster: data.selectedCluster
        })
    );

    const onChangePage = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.AlarmKPI,
        });
        if (selected_cluster!==null)
            window.history.pushState({"c_code":selected_cluster.enName},"", "/mobile/alarm/kpi/"+selected_cluster.enName+"/all");
        else if (selected_building!==null)
            window.history.pushState({"b_code":selected_building.building_code},"", "/mobile/alarm/kpi/"+sorted_buildings[0].building_code);
        else
            window.history.pushState({},"", "/mobile/alarm/kpi");
        
        handleClose();
    }

    const onChangePageTest = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.TestPush,
        });
        
        window.history.pushState({},"", "/mobile/testpush");
        handleClose();
    }

    const onChangePageApprovalCenter = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.ApprovalCenter,
        });
        
        window.history.pushState({},"", "/mobile/approvalcenter");
        handleClose();
    }

    const onLogout = () => {
        window.localStorage.removeItem("xauth");
        window.location.href = "/mobile/login2";
    };

    return (
        <Offcanvas show={show} onHide={handleClose} scroll backdrop placement="top" className="myOffcanvas" id="menuOffcanvas">
            <Offcanvas.Header className="border" closeButton>
                <Offcanvas.Title onClick={()=> onLogout()}><MdOutlineLogout/>&thinsp;{t("nav.logout")}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ListGroup variant="flush" className="text-center">
                    <ListGroup.Item onClick={()=>{onChangePage()}}>
                        <FaRegBell/>&thinsp;{t("nav.menu.alarm")}&ensp;/&ensp;
                        <FiClipboard/>&thinsp;{t("nav.menu.notice_b")}
                    </ListGroup.Item>
                    <ListGroup.Item onClick={()=> onChangePageApprovalCenter()}>
                        <RiMailCheckLine/>&thinsp;{t("nav.menu.approval_center")}
                    </ListGroup.Item>
                    <ListGroup.Item onClick={()=> onChangePageTest()}>
                        <AiOutlineExperiment/>&thinsp;{t("nav.menu.test")}
                    </ListGroup.Item>
                </ListGroup>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

MenuOffcanvas.propTypes = {
    show: PropTypes.bool.isRequired,
    setShowFn: PropTypes.func.isRequired
};

export default MenuOffcanvas;