import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import { Offcanvas, ListGroup, Accordion, NavDropdown } from 'react-bootstrap';
import { useParams } from "react-router-dom";

import { dataConstant, CLUSTERS } from '../../constants';
import {ReactComponent as MapIcon} from '../../assets/nav/pin-map-fill.svg'
import {ReactComponent as BuildingIcon} from '../../assets/nav/building.svg'
import {ReactComponent as HospitalIcon} from '../../assets/nav/hospital.svg'


const SearchOffcanvas = ({
    show = false,
    setShowFn = false,
    level = null
}) => {
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    const handleClose = () => setShowFn(false);
    const [title, setTitle] = useState(t("nav.search_venue"));
    const [clusterMenu, setClusterMenu] = useState([]);
    const [defaultOpen, setDefaultOpen] = useState("");

    var { buildings, selected_building, sorted_buildings, clusters, selected_cluster} = useSelector(
        ({core, data, state}) => ({
            buildings: data.buildingList,
            sorted_buildings: data.sortedBuildingList,
            selected_building: data.selectedBuilding,
            clusters: data.clusterList,
            selected_cluster: data.selectedCluster
        })
    );

    useEffect(()=>{
        defineMenu();
    },[buildings])

    useEffect(()=>{
        if (selected_building!==null)
            if (i18n.language === "en")
                setTitle(sorted_buildings[0].enName);
            else
                setTitle(sorted_buildings[0].tcName);
        else if (selected_cluster!==null)
            if (i18n.language === "en")
                setTitle(selected_cluster.enName);
            else
                setTitle(selected_cluster.tcName);
        else 
            setTitle(t("nav.all_cluster"));

    },[selected_building,selected_cluster,i18n.language])  

    const defineMenu=()=>{
        let c = [];
        let cluster_menu = []

        buildings.forEach((b) => {
            if (!c.includes(b.region_code)) {
                c.push(b.region_code)
                cluster_menu.push({"enName":b.region_code,"tcName":b.region_tc});
            }
        });

        if (cluster_menu.length===1) setDefaultOpen(`nav_${cluster_menu[0].enName}`);
        setClusterMenu(cluster_menu);
    }

    const onSelectAllCluster = () =>{
        if (selected_building===null && selected_cluster===null){
            handleClose();
            return;
        }

        dispatch({
            type: dataConstant.type.CHANGE_BUILDING,
            building: null,
        });
        dispatch({
            type: dataConstant.type.UPDATE_BUILDING_LIST,
            buildingList: buildings
        });
        dispatch({
            type: dataConstant.type.CHANGE_CLUSTER,
            cluster: null
        });
        changeUrl();
        handleClose();
    }

    const onSelectCluster = (c_obj,b_list,b_list_chubb) => {
        if (selected_cluster===c_obj){
            handleClose();
            return;
        }
        dispatch({
            type: dataConstant.type.CHANGE_BUILDING,
            building: null,
        });
        dispatch({
            type: dataConstant.type.CHANGE_CLUSTER,
            cluster: c_obj,
        });
        dispatch({
            type: dataConstant.type.UPDATE_BUILDING_LIST,
            buildingList: [...b_list,...b_list_chubb]
        });
        changeUrl(c_obj,null);
        handleClose();
    }

    const onSelectBuilding=(b_obj)=>{
        if (selected_building===b_obj.building_code){
            handleClose();
            return;
        }

        if (b_obj !== null){
            dispatch({
                type: dataConstant.type.CHANGE_CLUSTER,
                cluster: null
            });
            // dispatch({
            //     type: dataConstant.type.CHANGE_BUILDING,
            //     building: b_obj,
            // });
            dispatch({
                type: dataConstant.type.UPDATE_BUILDING_LIST,
                buildingList: [b_obj]
            });
            changeUrl(null,b_obj);
         }
         handleClose();
    }

    const changeUrl=(change2c=null, change2b=null,)=>{

        if (window.location.pathname.indexOf('/mobile/alarm/kpi') > -1)
            if (change2c!==null)
                window.history.pushState({"c_code":change2c.enName},"", "/mobile/alarm/kpi/"+change2c.enName+"/all");
            else if (change2b!==null)
                window.history.pushState({"b_code":change2b.building_code},"", "/mobile/alarm/kpi/"+change2b.building_code);
            else
                window.history.pushState({},"", "/mobile/alarm/kpi");

        if (window.location.pathname.indexOf('/mobile/alarm/record') > -1)
            if (change2c!==null)
                window.history.pushState({"c_code":change2c.enName,"level":level},"", "/mobile/alarm/record/"+level+"/"+change2c.enName+"/all");
            else if (change2b!==null)
                window.history.pushState({"b_code":change2b.building_code,"level":level},"", "/mobile/alarm/record/"+level+"/"+change2b.building_code);
            else
            window.history.pushState({},"", "/mobile/alarm/record/"+level);
    }

    const renderBuildingItem = (b) => {
        return (
            <ListGroup.Item key={`nav_${b.building_code}`} onClick={()=>{onSelectBuilding(b)}}>
                {b.id<1000?<BuildingIcon/>:<HospitalIcon/>}
                &ensp; {(i18n.language==="en")?b.enName:b.tcName}
            </ListGroup.Item>
        )
    }

    const renderList = (list) => {
        return list.map((l)=>{
            return renderBuildingItem(l);
        });
    }

    const renderAccordion=(c_obj, b_list, b_list_chubb)=>{
        let title = window.localStorage.i18nextLng==="en"? c_obj.enName: c_obj.tcName;
        return (
            <Accordion.Item eventKey={`nav_${c_obj.enName}`} key={`nav_${c_obj.enName}`}>
                <Accordion.Header><MapIcon/>&ensp; {title}</Accordion.Header>
                <Accordion.Body>
                    <ListGroup variant="flush">
                        <ListGroup.Item key={`nav_${c_obj.enName}_all`} onClick={()=>{onSelectCluster(c_obj,b_list,b_list_chubb)}}>
                            <BuildingIcon/>&ensp; {t("nav.all_venue")} ({title})
                        </ListGroup.Item>
                        {renderList(b_list)}
                    </ListGroup>
                </Accordion.Body>
            </Accordion.Item>
        )
    }

    const renderBody = (cluster_list) =>{

        let listHKE = [];
        let listHKW = [];
        let listKC = [];
        let listKE = [];
        let listKW = [];
        let listNTE = [];
        let listNTW = [];

        let listHKE_chubb = [];
        let listHKW_chubb = [];
        let listKC_chubb = [];
        let listKE_chubb = [];
        let listKW_chubb = [];
        let listNTE_chubb = [];
        let listNTW_chubb = [];

        buildings.forEach((b) => {
            if (b.id>2000) {
                if (b.region_code==="HKE") listHKE_chubb.push(b);
                if (b.region_code==="HKW") listHKW_chubb.push(b);
                if (b.region_code==="KC") listKC_chubb.push(b);
                if (b.region_code==="KE") listKE_chubb.push(b);
                if (b.region_code==="KW") listKW_chubb.push(b);
                if (b.region_code==="NTE") listNTE_chubb.push(b);
                if (b.region_code==="NTW") listNTW_chubb.push(b);
            }
            else{
                if (b.region_code==="HKE") listHKE.push(b);
                if (b.region_code==="HKW") listHKW.push(b);
                if (b.region_code==="KC") listKC.push(b);
                if (b.region_code==="KE") listKE.push(b);
                if (b.region_code==="KW") listKW.push(b);
                if (b.region_code==="NTE") listNTE.push(b);
                if (b.region_code==="NTW") listNTW.push(b);
            }
        });

        return cluster_list.map((c)=>{
            if (c.enName === "HKE") return renderAccordion(c, listHKE, listHKE_chubb);
            if (c.enName === "HKW") return renderAccordion(c, listHKW, listHKW_chubb);
            if (c.enName === "KC") return renderAccordion(c, listKC, listKC_chubb);
            if (c.enName === "KE") return renderAccordion(c, listKE, listKE_chubb);
            if (c.enName === "KW") return renderAccordion(c, listKW, listKW_chubb);
            if (c.enName === "NTE") return renderAccordion(c, listNTE, listNTE_chubb);
            if (c.enName === "NTW") return renderAccordion(c, listNTW, listNTW_chubb);
        });
    }

    return (
        <Offcanvas show={show} onHide={handleClose} scroll backdrop placement="start">
            <Offcanvas.Header className="border" closeButton>
                <Offcanvas.Title>{t("nav.search_venue")}: <br/><span style={{"color": "grey"}}>{title}</span></Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Accordion id="ClusterSearchAccordion" defaultActiveKey={[defaultOpen]} alwaysOpen>
                    <Accordion.Item eventKey="nav_all" id="AllVenuesAccordion" onClick={()=>{onSelectAllCluster()}}>
                        <Accordion.Header>{t("nav.all_cluster")}</Accordion.Header>
                    </Accordion.Item>
                    {renderBody(clusterMenu)}
                </Accordion>
            </Offcanvas.Body>
        </Offcanvas>
    )
}

SearchOffcanvas.propTypes = {
    show: PropTypes.bool.isRequired,
    setShowFn: PropTypes.func.isRequired,
    // level: PropTypes.string.isRequired,
};

export default SearchOffcanvas;