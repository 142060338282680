export const CLUSTERS = [
    {
        "enName":"HKW",
        "tcName":"香港西"
    },
    {
        "enName":"HKE",
        "tcName":"香港東"
    },
    {
        "enName":"KW",
        "tcName":"九龍西"
    },
    {
        "enName":"KE",
        "tcName":"九龍東"
    },
    {
        "enName":"KC",
        "tcName":"九龍中"
    },
    {
        "enName":"NTW",
        "tcName":"新界西"
    },
    {
        "enName":"NTE",
        "tcName":"新界東"
    }
];

export const PAGES = {
    AlarmKPI:"AlarmKPI",
    AlarmRecord:"AlarmRecord",
    TestPush:"TestPush",
    ApprovalCenter:"ApprovalCenter",
    PreventiveMaint:"PreventiveMaint"
}

export const LEVELS = {
    FATAL:"FATAL",
    CRITICAL:"CRITICAL",
    ALERT:"ALERT"
}

export const TRAYS = {
    EL:"EL",
    HVAC:"HVAC",
    LAE:"LAE",
    FS:"FS",
    EMG:"EMG"
}