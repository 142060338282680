export const SET_BUILDING_LIST = "SET_BUILDING_LIST"
export const UPDATE_BUILDING_LIST = "UPDATE_BUILDING_LIST"
export const CHANGE_BUILDING = "CHANGE_BUILDING"
export const SET_CLUSTER_LIST = "SET_CLUSTER_LIST"
export const CHANGE_CLUSTER = "CHANGE_CLUSTER"
export const CHANGE_PAGE = "CHANGE_PAGE"
export const CHANGE_LEVEL = "CHANGE_LEVEL"


export const dataConstant = {
    type: {
        SET_BUILDING_LIST: "SET_BUILDING_LIST",
        UPDATE_BUILDING_LIST: "UPDATE_BUILDING_LIST",
        CHANGE_BUILDING: "CHANGE_BUILDING",
        SET_CLUSTER_LIST: "SET_CLUSTER_LIST",
        CHANGE_CLUSTER: "CHANGE_CLUSTER",
        CHANGE_PAGE: "CHANGE_PAGE",
        CHANGE_LEVEL: "CHANGE_LEVEL"
    }
};