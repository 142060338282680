import React, {useState, useEffect} from 'react';
import { Container, Navbar, Nav, Row, Col, Offcanvas } from 'react-bootstrap';
import {useParams} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { IoIosArrowDropdownCircle } from "react-icons/io";

import { dataConstant, PAGES } from '../constants';
import Index_withAppLoader from './index_withAppLoader'
import MenuOffcanvas from './search/MenuOffcanvas';

import Logo from "../assets/logo/nav_logo.png"

function Index(props){
    var { sorted_buildings, selected_building, selected_cluster, level} = useSelector(
        ({core, data, state}) => ({
            sorted_buildings: data.sortedBuildingList,
            selected_building: data.selectedBuilding,
            selected_cluster: data.selectedCluster,
            level: data.level
        })
    );

    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();
    let { c_code,b_code } = useParams();
    const [subPath, setSubPath] = useState('');
    const [showMenuOffcanvas, setShowMenuOffcanvas] = useState(false);

    const { lang } = useSelector(({core})=>({
        lang: core.lang
      }));

    useEffect(() => {
        if (window.localStorage.i18nextLng !== lang) {
            i18n.changeLanguage(window.localStorage.i18nextLng);
        }
    }, [lang]);

    useEffect(() => {
        if (selected_cluster!==null)
            setSubPath('/'+selected_cluster.enName+"/all");
        else if (selected_building !== null) 
            setSubPath('/'+sorted_buildings[0].building_code);
        else
            setSubPath('');
    }, [selected_building,selected_cluster]);

    const onLanguageClick = () => {
        var langs = ["en", "zh-hk"];
        var current_lang = i18n.language;
        var index = langs.indexOf(current_lang);
        var lang = index + 1 > langs.length - 1 ? langs[0] : langs[index + 1];

        i18n.changeLanguage(lang);
        moment.locale(lang);
    };

    const onLogout = () => {
        window.localStorage.removeItem("xauth");
        window.location.href = "/mobile/login2";
    };

    const onChangePage = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.AlarmKPI,
        });
        if (selected_cluster!==null)
            window.history.pushState({"c_code":selected_cluster.enName},"", "/mobile/alarm/kpi/"+selected_cluster.enName+"/all");
        else if (selected_building!==null)
            window.history.pushState({"b_code":selected_building.building_code},"", "/mobile/alarm/kpi/"+sorted_buildings[0].building_code);
        else
            window.history.pushState({},"", "/mobile/alarm/kpi");
    }

    const onChangePageTest = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.TestPush,
        });
        
        window.history.pushState({},"", "/mobile/testpush");
    }

    const onChangePageApprovalCenter = () => {
        dispatch({
            type: dataConstant.type.CHANGE_PAGE,
            page: PAGES.ApprovalCenter,
        });
        
        window.history.pushState({},"", "/mobile/approvalcenter");
    }

    const renderCurrentPageText = () => {
        if (window.location.pathname.indexOf('/testpush') > -1)
            return(
                <>
                {t("nav.menu.test")}&thinsp;<IoIosArrowDropdownCircle/>
                </>
            )

        if (window.location.pathname.indexOf('/approvalcenter') > -1)
            return(
                <>
                {t("nav.menu.approval_center")}&thinsp;<IoIosArrowDropdownCircle/>
                </>
            )

        if (window.location.pathname.indexOf('/preventivemaint') > -1)
            return(
                <>
                {t("nav.menu.preventive_maint")}&thinsp;<IoIosArrowDropdownCircle/>
                </>
            )
        
        if (window.location.pathname.indexOf('/alarm/record') > -1){
            if (level==='FATAL')
                return(
                    <>
                    {t("nav.menu.alarm")} ({t("alarm.level.fatal")})&thinsp;<IoIosArrowDropdownCircle/>
                    </>
                )

            if (level==='CRITICAL')
                return(
                    <>
                    {t("nav.menu.alarm")} ({t("alarm.level.critical")})&thinsp;<IoIosArrowDropdownCircle/>
                    </>
                )

            if (level==='ALERT')
                return(
                    <>
                    {t("nav.menu.notice_b")} ({t("alarm.level.alert")})&thinsp;<IoIosArrowDropdownCircle/>
                    </>
                )
        }
            
        return (
            <>
            {t("nav.menu.alarm")}&ensp;/&ensp;{t("nav.menu.notice_b")}&thinsp;<IoIosArrowDropdownCircle/>
            </>
        )
    }

    return (
        <>
            <Navbar variant="dark" fixed="top" id='myNav' className='sticky-nav'>
                <Container>
                    <Row className='w-100 text-center mx-auto'>
                        <Col xs="1" className='px-0' >
                            <img onClick={()=>{onChangePage()}} alt="" src={Logo} height="23" className="d-inline-block align-top" />&thinsp;
                        </Col>
                        <Col>
                            <div onClick={()=>{setShowMenuOffcanvas(true)}}>
                                {renderCurrentPageText()}
                            </div>
                        </Col>
                        <Col xs="1" className='px-0' >
                            <Nav>
                                <Nav.Link href="#" className="px-0 py-0" onClick={()=> onLanguageClick()}>{t("nav.language")}</Nav.Link>
                            </Nav>
                        </Col>
                    </Row>
                    <MenuOffcanvas 
                        show={showMenuOffcanvas}
                        setShowFn={setShowMenuOffcanvas}
                    />
                    {/* <Navbar.Brand href="">
                        <FiMenu onClick={()=>{setShowMenuOffcanvas(true)}}/>&thinsp;
                    </Navbar.Brand>
                    <Navbar.Brand href="/mobile">
                        <img alt="" src={Logo} height="30" className="d-inline-block align-top" />&thinsp;
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto" >
                            <Nav.Link href="" onClick={()=>{onChangePage()}}>{t("nav.menu.alarm")}</Nav.Link>
                            <Nav.Link href="" onClick={()=> onChangePageApprovalCenter()}>{t("nav.menu.approval_center")}</Nav.Link>
                            <Nav.Link href="" onClick={()=> onChangePageTest()}>{t("nav.menu.test")}</Nav.Link>
                        </Nav>
                        <Nav>
                            <Nav.Link href="#" onClick={()=> onLanguageClick()}>{t("nav.language")}</Nav.Link>
                            <Nav.Link href="#" onClick={()=> onLogout()}>{t("nav.logout")}</Nav.Link>
                        </Nav>
                    </Navbar.Collapse> */}
                </Container>
            </Navbar>
            <Index_withAppLoader/>
            <div className="fixed-bottom py-1" id="BottomNav">
                <Container>
                    <span>HSD RDCC</span>&thinsp;|&thinsp;
                    <span>{t("common.rights")}</span>
                </Container>
            </div>
        </>
    )
}

export default Index