import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { Spinner } from 'react-bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import "./AppMobile.scss"

import Index from "./view/index";
import LoginForm from "./view/login/LoginForm";
import TestPush from "./view/test/testpush";
import AlarmManage from "./view/admin/AlarmManage"

import './i18n';

import { useState, useEffect } from 'react';
import axios from 'axios';
import {API_URL} from "./utils/api"

const loading = () => (
  <div className="loading">
      <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
  </div>
);

function App() {

  return (
    <div className="App">
      <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
              <React.Suspense fallback={loading()}>
                <BrowserRouter>
                  <Routes>
                    <Route
                      path={`/mobile/login2`}
                      name="login"
                      element={<LoginForm/>}
                    />
                    <Route
                      path={`/mobile/testpush`}
                      name="testpush"
                      element={<Index page="testpush"/>}
                    />
                    <Route
                      path={`/mobile/approvalcenter`}
                      name="approvalcenter"
                      element={<Index page="approvalcenter"/>}
                    />
                    <Route
                      path={`/mobile/admin/alarmmanage`}
                      name="alarmmanage"
                      element={<AlarmManage/>}
                    />
                    <Route
                      path={`/mobile/alarm/kpi`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    <Route
                      path={`/mobile/alarm/kpi/:c_code/all`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    <Route
                      path={`/mobile/alarm/kpi/:b_code`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    <Route
                      path={`/mobile/alarm/record/:level`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    <Route
                      path={`/mobile/alarm/record/:level/:c_code/all`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    <Route
                      path={`/mobile/alarm/record/:level/:b_code`}
                      name="index"
                      element={<Index page="alarm"/>}
                    />
                    
                    <Route path="*" element={<Navigate to="/mobile/alarm/kpi" replace />} />
                  </Routes>
                </BrowserRouter>
              </React.Suspense>
          </PersistGate>
        </Provider>
      </div>
  );
}

export default App;
