import React, {useState, useEffect, useRef} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { dataConstant } from '../../constants';
import {ReactComponent as SearchSvg} from '../../assets/nav/search.svg';
import SearchOffcanvas from './SearchOffcanvas';

function SearchBar(props){
    const dispatch = useDispatch();
    const {t, i18n} = useTranslation();

    var { buildings, sorted_buildings, selected_building, selected_cluster, level} = useSelector(
        ({core, data, state}) => ({
            buildings: data.buildingList,
            sorted_buildings: data.sortedBuildingList,
            selected_building: data.selectedBuilding,
            selected_cluster: data.selectedCluster,
            level: data.level
        })
    );

    const [title, setTitle] = useState(t("nav.all_cluster"));
    const [showSearchOffcanvas, setShowSearchOffcanvas] = useState(false);

    useEffect(()=>{

        if (selected_building!==null)
            if (i18n.language === "en")
                setTitle(sorted_buildings[0].enName);
            else
                setTitle(sorted_buildings[0].tcName);
        else if (selected_cluster!==null)
            if (i18n.language === "en")
                setTitle(selected_cluster.enName);
            else
                setTitle(selected_cluster.tcName);
        else 
            setTitle(t("nav.all_cluster"));

    },[selected_building,selected_cluster,i18n.language])


    return (
        <Navbar variant="dark" sticky="top" className='my-0 py-0 sticky-nav' id='myNav2'>
            <Container>
                <div id='destinationTitle' onClick={()=> {setShowSearchOffcanvas(true)}}>
                    <SearchSvg/>&thinsp;&thinsp;
                    {t("nav.search_venue")}: {title}
                </div>
            </Container>
            <SearchOffcanvas 
                show={showSearchOffcanvas}
                setShowFn={setShowSearchOffcanvas}
                level={level}
            />
        </Navbar>
    )
}

export default SearchBar;