import React, { Component } from 'react'
import { useTranslation } from "react-i18next";
import {ReactComponent as EMSD_ICON} from "../../assets/logo/emsd.svg"
import {ReactComponent as LOGIN_ICON} from "../../assets/login/login_icon.svg"
import {ReactComponent as PASSWORD_ICON} from "../../assets/login/password_icon.svg"
import './style.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import { useSelector,useDispatch } from "react-redux";
import { useSearchParams  } from "react-router-dom";
import {API_URL} from "../../utils/api"

function LoginForm () {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const [isTokenValid,setIsTokenValid] = useState(null);
    const [username,setUsername] = useState('');
    const [password,setPassword] = useState('');
    const [incorrectCredential,setIncorrectCredential] = useState(0);
    const [rememberMe,setRememberMe] = useState(true);
    const [replyMsg,setReplyMsg] = useState('');

    useEffect(() => { // componentdidmount
        const authorization = searchParams.get('authorization');
        // console.log("Authorization: ")
        // console.log(authorization);
        const xauth = window.localStorage.getItem('xauth');
        if (typeof xauth !== 'undefined') {
            axios({
                method: "post",
                url: `${API_URL}/api/clinic/user/verify`,
                headers: {xauth, "Authorization":authorization}
            })
            .then((response) => {
                setTimeout(function() {
                    setIsTokenValid(true);
                    if (typeof response.headers.xauth !== 'undefined') {
                        window.localStorage.setItem("xauth", response.headers.xauth);
                        window.location.href = "/mobile";
                    }
                }, 800);                
            }).catch((error) => {
                setTimeout(function() {
                    setIsTokenValid(false);
                }, 800);      
            })
        }
    }, []);

    const handleRememberChange = (val) => {
        setRememberMe(val);
    }
    
    const checkCredential = () => {
        axios({
            method: "post",
            url: `${API_URL}/api/clinic/user/login`,
            data: {
                username,
                password,
                rememberMe
            }
        })
        .then((response) => {
            setIncorrectCredential(200);
            if (typeof response.headers.xauth !== 'undefined') {
                window.localStorage.setItem("xauth", response.headers.xauth);
                window.location.href = "/mobile/alarm/kpi";
            }
            
        }).catch((error) => {
            setIncorrectCredential(error.response.status);
            if (error.response.data.replyMsg !== null){
                setReplyMsg(error.response.data.replyMsg)
            }
            else {
                setReplyMsg("Login fails.")
            }
        })
    }
               
    const submitForm = (event) => {
        if(event.key === 'Enter'){
            checkCredential();
        }
    }

    const renderForm = () => {
        return (
            <div className="container">
                <div className="login-header">
                    <div className="logo-row-wrapper">
                        <div className="logo-row-title">
                            <span>
                            <EMSD_ICON />
                            </span>
                            <span className="logo-title">
                                EMSD
                            </span>
                        </div>
                        <div className="logo-row-fullname">
                            <span className="name1">Regional Digitization Control Centre</span>
                        </div>
                        <div className="logo-row-fullname">
                            <span className="name2">Health Sector Division </span>
                        </div>
                    </div>
                </div>
                <div className="login-board">
                    <div className="login-form">
                        <div className="fields">
                            <div className="field">

                                <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
                                    <span className="ant-input-prefix">
                                        <span role="img" aria-label="user" className="anticon anticon-user">
                                            <LOGIN_ICON width="1em" height="1em"/>
                                        </span>
                                    </span>
                                    <input placeholder="Post" type="text" className="ant-input ant-input-lg" onChange={event => setUsername(event.target.value)} onKeyPress={submitForm}/>
                                </span>

                            </div> 
                            <div className="field">
                                
                                <span className="ant-input-affix-wrapper ant-input-affix-wrapper-lg">
                                    <span className="ant-input-prefix">
                                        <span role="img" aria-label="user" className="anticon anticon-user">
                                            <PASSWORD_ICON width="1em" height="1em"/>
                                        </span>
                                    </span>
                                    <input placeholder="Workstation Password" type="password" className="ant-input ant-input-lg" onChange={event => setPassword(event.target.value)} onKeyPress={submitForm}/>
                                </span>
                            </div>
                            <div className="field">
                                <button className={"btn"} id="login_btn" onClick={event => checkCredential()}>{"Login"}</button>
                            </div>
                            {(incorrectCredential!==200) && 
                                <div className="field">
                                    <span style={{color: 'red'}}>{replyMsg}</span>
                                </div>
                            }
                            <div className="field">
                                <label className="ant-checkbox-wrapper">
                                    <span className="ant-checkbox ant-checkbox-checked">
                                        <input type="checkbox" className="ant-checkbox-input" onChange={event => handleRememberChange(event.target.checked)} defaultChecked/>
                                    </span>
                                    <span>Remember</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderLoading = () => {
        return (
            <div className="loading">
                <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            </div>
        )
    }


    return (
        <div className="page login-page">
            {
                isTokenValid===null?renderLoading()
                :isTokenValid===false? renderForm()
                :renderLoading()
            }
        </div>
    )
    
}

export default LoginForm