import React, {useEffect, useCallback} from 'react';

import SearchBar from './search/SearchBar'
import AlarmMain from './alarm/Main'
import TestPush from './test/testpush';
import ApprovalCenter from './approvalCenter/Main';

function Main(){
    
    if (window.location.pathname.indexOf('/testpush') > -1)
    return(
        <TestPush/>
    )

    if (window.location.pathname.indexOf('/approvalcenter') > -1)
    return(
        <ApprovalCenter/>
    )

    return (
        <div>
            <SearchBar/>
            <AlarmMain/>
        </div>
    )
}

export default Main;