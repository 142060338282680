import React, {useState, useEffect, useRef} from 'react';
import { useTranslation } from "react-i18next";
import { Container,Card,Button,Table,Alert} from 'react-bootstrap';
import { axiosWithXAuth } from "../../utils/functions";
import { API_URL} from '../../utils/api';

function TestPush(){

    const {t, i18n} = useTranslation();

    const [disable_init, setDisable_init] = useState(false);
    const [disable_acked, setDisable_acked] = useState(false);
    const [disable_escalate, setDisable_escalate] = useState(false);
    const [disable_resolved, setDisable_resolved] = useState(false);
    const [disable_remind, setDisable_remind] = useState(false);
    
    const [countdown_init, setCountdown_init] = useState(0);
    const [countdown_acked, setCountdown_acked] = useState(0);
    const [countdown_escalate, setCountdown_escalate] = useState(0);
    const [countdown_resolved, setCountdown_resolved] = useState(0);
    const [countdown_remind, setCountdown_remind] = useState(0);
    const countdown_default = 60;
    const timeout = 60000;

    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);

    useEffect(()=>{
        if (countdown_init === countdown_default){
            console.log("countdown_init begin");
            let i = countdown_default;
            let myInterval = setInterval(() => {
                i = i - 1; 
                if (i > 0) {
                    setCountdown_init(i);
                }
                else {
                    clearInterval(myInterval);
                    console.log("clearInterval countdown_init");
                } 
            }, 1000);
        }
    }, [countdown_init]);

    useEffect(()=>{
        if (countdown_acked === countdown_default){
            console.log("countdown_acked begin");
            let i = countdown_default;
            let myInterval = setInterval(() => {
                i = i - 1; 
                if (i > 0) {
                    setCountdown_acked(i);
                }
                else {
                    clearInterval(myInterval);
                    console.log("clearInterval countdown_acked");
                } 
            }, 1000);
        }
    }, [countdown_acked]);

    useEffect(()=>{
        if (countdown_escalate === countdown_default){
            console.log("countdown_escalate begin");
            let i = countdown_default;
            let myInterval = setInterval(() => {
                i = i - 1; 
                if (i > 0) {
                    setCountdown_escalate(i);
                }
                else {
                    clearInterval(myInterval);
                    console.log("clearInterval countdown_escalate");
                } 
            }, 1000);
        }
    }, [countdown_escalate]);

    useEffect(()=>{
        if (countdown_resolved === countdown_default){
            console.log("countdown_resolved begin");
            let i = countdown_default;
            let myInterval = setInterval(() => {
                i = i - 1; 
                if (i > 0) {
                    setCountdown_resolved(i);
                }
                else {
                    clearInterval(myInterval);
                    console.log("clearInterval countdown_resolved");
                } 
            }, 1000);
        }
    }, [countdown_resolved]);

    useEffect(()=>{
        if (countdown_remind === countdown_default){
            console.log("countdown_remind begin");
            let i = countdown_default;
            let myInterval = setInterval(() => {
                i = i - 1; 
                if (i > 0) {
                    setCountdown_remind(i);
                }
                else {
                    clearInterval(myInterval);
                    console.log("clearInterval countdown_remind");
                } 
            }, 1000);
        }
    }, [countdown_remind]);
    
    const sendPush = (email_type)=>{    
 
        axiosWithXAuth({
        method: "post",
        url: `${API_URL}/api/clinic/test/testNotice`,
        data:{
                email_type: email_type,
            }
        })
        .then((response) => response.data)
        .then((data) => {
            setDisable(email_type);
            setShowSuccess(true);
        })
        .catch((error) => {
            setShowError(true);
        });
    }

    const setDisable = (email_type)=>{

        if (email_type==='init'){
            setDisable_init(true);
            setTimeout(() => setDisable_init(false), timeout);
            setCountdown_init(countdown_default);
        }
        if (email_type==='acked'){
            setDisable_acked(true);
            setTimeout(() => setDisable_acked(false), timeout);
            setCountdown_acked(countdown_default);
        }
        if (email_type==='escalate'){
            setDisable_escalate(true);
            setTimeout(() => setDisable_escalate(false), timeout);
            setCountdown_escalate(countdown_default);
        }
        if (email_type==='resolved'){
            setDisable_resolved(true);
            setTimeout(() => setDisable_resolved(false), timeout);
            setCountdown_resolved(countdown_default);
        }
        if (email_type==='remind'){
            setDisable_remind(true);
            setTimeout(() => setDisable_remind(false), timeout);
            setCountdown_remind(countdown_default);
        }

    }

    return (
        <Container className="pt-2 pb-5">
            <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible show={showSuccess} className="myMsgBar">
                {t("testpush.send_success")}<br/>
            </Alert>
            <Alert variant="danger" onClose={() => setShowError(false)} dismissible show={showError} className="myMsgBar">
                {t("common.err_msg")}<br/>
            </Alert>
            <div style={{ color: '#e5e5e5' }}>
                {t("testpush.text1")}
                <br/>
                <br/>
                *&ensp;{t("testpush.text2")}
                <br/>
                **&ensp;{t("testpush.text3")}
                <br/>
                {t("testpush.android")}: &ensp;
                <a target="_blank" href='https://support.google.com/android/answer/9079661?hl=en#zippy=%2Cturn-notifications-on-or-off-for-certain-apps'>{t("testpush.clickhere")}</a>
                <br/>
                {t("testpush.ios")}: &ensp;
                <a target="_blank" href='https://support.apple.com/zh-tw/guide/iphone/iph7c3d96bab/ios'>{t("testpush.clickhere")}</a>
            </div>
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>1. {t("testpush.email_type.init")}</Card.Title>
                    <Button variant="dark" onClick={()=>sendPush("init")} disabled={disable_init}>
                        {disable_init? t("testpush.send_btn_disable")+'...'+countdown_init:t("testpush.send_btn")}
                    </Button>
                    {/* <Card.Text className="mt-3">
                        <Table striped bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} >{t("testpush.table_title")}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>{t("alarm.level.critical")}</th>
                                    <th>{t("alarm.level.fatal")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("testpush.LN_to")}</td>
                                    <td>{t("testpush.designated")} AI / I </td>
                                    <td>{t("testpush.designated")} SI / RM</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.LN_cc")}</td>
                                    <td>SI, RM</td>
                                    <td>AI, I, E, SE</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.push_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                    <td>AI, I, E, SE, SI, RM</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Text> */}
                </Card.Body>
            </Card>
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>2. {t("testpush.email_type.acked")}</Card.Title>
                    <Button variant="dark" onClick={()=>sendPush("acked")} disabled={disable_acked}>
                        {disable_acked? t("testpush.send_btn_disable")+'...'+countdown_acked:t("testpush.send_btn")}
                    </Button>
                    {/* <Card.Text className="mt-3">
                        <Table striped bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} >{t("testpush.table_title")}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>{t("alarm.level.critical")}</th>
                                    <th>{t("alarm.level.fatal")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("testpush.LN_to")}</td>
                                    <td>AI, I, SI, RM </td>
                                    <td>AI, I, SI, RM, E, SE</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.push_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                    <td>AI, I, SI, RM, E, SE</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Text> */}
                </Card.Body>
            </Card>
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>3. {t("testpush.email_type.escalate")}</Card.Title>
                    <Card.Text>
                    {t("testpush.email_type_desc.escalate")}
                    </Card.Text>
                    <Button variant="dark" onClick={()=>sendPush("escalate")} disabled={disable_escalate}>
                        {disable_escalate? t("testpush.send_btn_disable")+'...'+countdown_escalate:t("testpush.send_btn")}
                    </Button>
                    {/* <Card.Text className="mt-3">
                        <Table striped bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} >{t("testpush.table_title")}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>{t("alarm.level.critical")}</th>
                                    <th>{t("alarm.level.fatal")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("testpush.LN_to")}</td>
                                    <td>SI, RM</td>
                                    <td>E, SE</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.LN_cc")}</td>
                                    <td>AI, I</td>
                                    <td>AI, I, SI, RM</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.push_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                    <td>AI, I, SI, RM, E, SE</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Text> */}
                </Card.Body>
            </Card>
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>4. {t("testpush.email_type.resolved")}</Card.Title>
                    <Card.Text>
                    </Card.Text>
                    <Button variant="dark" onClick={()=>sendPush("resolved")} disabled={disable_resolved}>
                        {disable_resolved? t("testpush.send_btn_disable")+'...'+countdown_resolved:t("testpush.send_btn")}
                    </Button>
                    {/* <Card.Text className="mt-3">
                        <Table striped bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} >{t("testpush.table_title")}</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>{t("alarm.level.critical")}</th>
                                    <th>{t("alarm.level.fatal")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("testpush.LN_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                    <td>AI, I, SI, RM, E, SE</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.push_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                    <td>AI, I, SI, RM, E, SE</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Text> */}
                </Card.Body>
            </Card>
            <Card className="mt-3">
                <Card.Body>
                    <Card.Title>5. {t("testpush.email_type.remind")}</Card.Title>
                    <Card.Text>
                        {t("testpush.email_type_desc.remind")}
                    </Card.Text>
                    <Button variant="dark" onClick={()=>sendPush("remind")} disabled={disable_remind}>
                        {disable_remind? t("testpush.send_btn_disable")+'...'+countdown_remind:t("testpush.send_btn")}
                    </Button>
                    {/* <Card.Text className="mt-3">
                        <Table striped bordered hover className="text-center">
                            <thead>
                                <tr>
                                    <th colSpan={3} >{t("testpush.table_title")}</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr>
                                    <td>{t("testpush.LN_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                </tr>
                                <tr>
                                    <td>{t("testpush.push_to")}</td>
                                    <td>AI, I, SI, RM</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Text> */}
                </Card.Body>
            </Card>
        </Container>
    )
}

export default TestPush