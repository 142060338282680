import React, { useState,useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Button, Offcanvas, Stack, Form  } from 'react-bootstrap';
import moment from "moment";

const DecideRemovalModal = ({
    requestObj,
    showModal = false,
    setShowModal,
    saveFn,
    isApprove
}) => {
    const {t} = useTranslation();
    const [show, setShow] = useState(showModal);
    const [reason, setReason] = useState("");
    const [validated, setValidated] = useState(false);

    let app_id = requestObj.app_id;
    let alarm_name = requestObj.name;
    let request_dt = moment(requestObj.remove_dt).format('YY-MM-DD HH:mm');
    let request_at = requestObj.remove_post;

    const handleClose = () => {
        setShow(false);
        setShowModal(false);
        setValidated(false);
    };

    useEffect(()=>{
        if (showModal){
            setValidated(false);
            setReason("");
        }
        setShow(showModal);
    }, [showModal])

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        else{
            saveFn(app_id, reason);
            handleClose();
        }
        setValidated(true);
    };

    const renderApproveContent=()=>{
        return (
            <>
            <div style={{"color":"#198754"}}>{t("approval_center.popup.greeting_a")}:</div>
            <Form>
                <Form.Group className="mb-3" controlId="decide_reason_textarea">
                    <Form.Control as="textarea" rows={4} maxLength="100" value={reason} onChange={(e)=>{setReason(e.target.value)}} placeholder={t("approval_center.popup.placeholder_a")}/>
                </Form.Group>
                <Stack gap={2} className="col-md-5 mx-auto mt-2">
                    <Button className="btn-submit" size="lg" bsPrefix="myBtn" onClick={()=>{saveFn(app_id, reason);handleClose();}}>{t("approval_center.popup.btn_submit")}</Button>
                    <Button className="btn-cancel" size="lg" bsPrefix="myBtn" onClick={handleClose}>{t("approval_center.popup.btn_cancel")}</Button>
                </Stack>
            </Form>
            </>
        )
    }

    const renderRejectContent=()=>{
        return (
            <>
            <div style={{"color":"#dc3545"}}>{t("approval_center.popup.greeting_r")}:</div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="decide_reason_textarea">
                    <Form.Control as="textarea" rows={4} maxLength="100" value={reason} onChange={(e)=>{setReason(e.target.value)}} placeholder={t("approval_center.popup.placeholder_r")} required/>
                    <Form.Control.Feedback type="invalid">
                        {t("approval_center.popup.reason_validate")}
                    </Form.Control.Feedback>
                </Form.Group>
                <Stack gap={2} className="col-md-5 mx-auto mt-2">
                    <Button className="btn-submit" size="lg" bsPrefix="myBtn" type="submit">{t("approval_center.popup.btn_submit")}</Button>
                    <Button className="btn-cancel" size="lg" bsPrefix="myBtn" onClick={handleClose}>{t("approval_center.popup.btn_cancel")}</Button>
                </Stack>
            </Form>
            </>
        )
    }

    return (
        <Offcanvas show={show} onHide={handleClose} backdrop autoFocus enforceFocus placement="bottom" className="myOffcanvas" id="decideRemovalOffcanvas">
            <Offcanvas.Header closeButton className="pb-0">
                <Offcanvas.Title>{alarm_name}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="pt-0">
                <div style={{"color": "grey"}}>{t("approval_center.requested_at")}: {request_dt}</div>
                <div style={{"color": "grey"}}>{t("approval_center.requested_by")}: {request_at}</div>
                <hr className="my-2"/>
                {
                    isApprove?
                    renderApproveContent()
                    :renderRejectContent()
                }
            </Offcanvas.Body>
        </Offcanvas>
        );
};

DecideRemovalModal.propTypes = {
    requestObj: PropTypes.object.isRequired,
    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    saveFn: PropTypes.func.isRequired,
    isApprove: PropTypes.bool.isRequired,
};

export default DecideRemovalModal;