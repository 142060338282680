import i18n from "../i18n";
import axios from 'axios';

export const numberWithCommas = (x, decimal) => {
  // var value = value > 1000 ?x.toFixed(1):x.toString()

  // var value = decimal ? x.toFixed(decimal) : x.toString()
  ///return Number.parseFloat(x).toFixed(0);
  var temp_value =
    x > 1000 || x < -1000
      ? Number.parseFloat(x).toFixed(0).toString()
      : Number.parseFloat(x).toFixed(1).toString();
  if (typeof decimal == "number") {
    temp_value = Number.parseFloat(x).toFixed(decimal).toString();
  }

  if (x == 0) {
    return 0
  }
  
  if (x < 2) {
    temp_value = Number.parseFloat(x).toFixed(2)
  }

  if (Number.isInteger(x) & x < 1000) {
    return x
  }
  
  return temp_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const groupBy = (list, key) => {
  return list.reduce((acc, obj) => {
    let group_item = obj[key];

    if (acc.some((item) => item.name === group_item) !== true) {
      acc.push({ name: group_item, records: [] });
    }

    acc = acc.map((item) => {
      if (item.name === group_item) {
        item.records.push(obj);
      }
      return item;
    });

    return acc;
  }, []);
};

export const date_range = (start_date, end_date) => {
  var dates = [];
  console.log({ start_date, end_date });
  while (start_date <= end_date) {
    dates.push(start_date);
    start_date = start_date.clone().add(1, "days");
  }
  return dates;
};

export const dateFilterDate = (type, date_from, date_to) => {
  var today = new Date();
  var og_today = new Date();

  if (type == "Y" || type == "yearly") {
    today = new Date(today.getFullYear(), 0, 1);
    og_today = new Date(today.getFullYear(), 11, 31);
  } else if (type == "M" || type == "monthly") {
    var year = today.getFullYear();
    var month = today.getMonth();
    today = new Date(year, month, 1);
    og_today = new Date(year, month + 1, 0);
  } else if (type == "W" || type == "weekly") {
    // one week data
    console.log("W");
    today.setDate(today.getDate() - ((today.getDay() + 6) % 7));
    console.log(today.getDate());
    console.log(today.getMonth());
    og_today.setDate(today.getDate() + 6);
    // if (og_today.getDay() < today.getDay()){
    //     og_today.setDate(og_today.getFullYear,og_today.getMonth() +1 ,og_today.getDay())
    // }
    // og_today.setDate(og_today.getDate() - 1)
    // today.setDate(today.getDate() -  7);
  } else if (type == "D" || type == "daily") {
    // one week data
    console.log("D");
    today.setDate(today.getDate());
    og_today.setDate(today.getDate());
    // today.setDate(today.getDate() -  7);
  } else {
    const today = new Date(date_from);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;

    const today2 = new Date(date_to);
    var dd = String(today2.getDate()).padStart(2, "0");
    var mm = String(today2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today2.getFullYear();
    var today2Date = yyyy + "-" + mm + "-" + dd;

    return [todayDate, today2Date];
  }

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  var og_dd = String(og_today.getDate()).padStart(2, "0");
  var og_mm = String(og_today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var og_yyyy = og_today.getFullYear();

  var end_str_date = yyyy + "-" + mm + "-" + dd;
  var og_str_date = og_yyyy + "-" + og_mm + "-" + og_dd;

  return [end_str_date, og_str_date];
};

export const dateFilterDate2 = (type, date_from, date_to) => {
  var today = new Date();
  var og_today = new Date();

  if (type == "Y" || type == "yearly") {
    // var day = today.getDate()
    // var month = today.getMonth()
    // today = new Date(today.getFullYear(), month, day);
    // og_today = new Date(today.getFullYear() - 1,month,day);
    og_today.setDate(today.getDate());
    var temp = new Date(today.getFullYear() - 1, today.getMonth() + 1);
    today = temp;
    //today.setDate(og_today.getDate() - 364);
  } else if (type == "M" || type == "monthly") {
    // var year = today.getFullYear()
    // var month = today.getMonth()
    var day = today.getDate();
    // today = new Date(year,month,1);
    // og_today = new Date(year,month+1,0);
    og_today.setDate(today.getDate());
    if (day == 31) {
      today.setDate(og_today.getDate() - 30);
    } else {
      today.setDate(og_today.getDate() - 29);
    }
  } else if (type == "W" || type == "weekly") {
    // one week data
    // console.log("W")
    // today.setDate(today.getDate() - (today.getDay() + 6) % 7);
    // console.log(today.getDate());
    // console.log(today.getMonth());
    // og_today.setDate(today.getDate() + 7);
    // if (og_today.getDay() < today.getDay()){
    //     og_today.setDate(og_today.getFullYear,og_today.getMonth() +1 ,og_today.getDay())
    // }
    // og_today.setDate(og_today.getDate() - 1)
    // today.setDate(today.getDate() -  7);
    og_today.setDate(today.getDate());
    today.setDate(og_today.getDate() - 6);
  } else if (type == "D" || type == "daily") {
    // one week data
    console.log("D");
    today.setDate(today.getDate());
    og_today.setDate(today.getDate());
    // today.setDate(today.getDate() -  7);
  } else {
    const today = new Date(date_from);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;

    const today2 = new Date(date_to);
    var dd = String(today2.getDate()).padStart(2, "0");
    var mm = String(today2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today2.getFullYear();
    var today2Date = yyyy + "-" + mm + "-" + dd;

    return [todayDate, today2Date];
  }

  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  var og_dd = String(og_today.getDate()).padStart(2, "0");
  var og_mm = String(og_today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var og_yyyy = og_today.getFullYear();

  var end_str_date = yyyy + "-" + mm + "-" + dd;
  var og_str_date = og_yyyy + "-" + og_mm + "-" + og_dd;

  return [end_str_date, og_str_date];
};

export const dateFilterComparison = (type, start_date_og, end_date_og) => {
  const W1 = dateFilterDate(type);

  var firstDate = new Date(W1[0]);
  var secondDate = new Date(W1[1]);

  var finaloutput = [];
  if (type == "W" || type == "weekly") {
    var twoWeeksOne = new Date();
    var twoWeeksTwo = new Date();
    var oneWeeksOne = new Date();
    var oneWeeksTwo = new Date();

    oneWeeksOne.setDate(firstDate.getDate() - 7);
    oneWeeksTwo.setDate(firstDate.getDate() - 1);

    twoWeeksOne.setDate(firstDate.getDate() - 14);
    twoWeeksTwo.setDate(firstDate.getDate() - 8);

    var dates = [
      twoWeeksOne,
      twoWeeksTwo,
      oneWeeksOne,
      oneWeeksTwo,
      firstDate,
      secondDate,
    ];

    var i;
    for (i = 0; i < dates.length; i++) {
      var dd = String(dates[i].getDate()).padStart(2, "0");
      var mm = String(dates[i].getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = dates[i].getFullYear();
      var date_string = yyyy + "-" + mm + "-" + dd;
      finaloutput.push(date_string);
    }
  } else if (type == "M" || type == "monthly") {
    var year = firstDate.getFullYear();
    var month = firstDate.getMonth();

    var twoMonthsOne = new Date(year, month - 2, 1);
    var twoMonthsTwo = new Date(year, month - 1, 0);
    var oneMonthsOne = new Date(year, month - 1, 1);
    var oneMonthsTwo = new Date(year, month, 0);

    var dates = [
      twoMonthsOne,
      twoMonthsTwo,
      oneMonthsOne,
      oneMonthsTwo,
      firstDate,
      secondDate,
    ];

    var i;
    for (i = 0; i < dates.length; i++) {
      var dd = String(dates[i].getDate()).padStart(2, "0");
      var mm = String(dates[i].getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = dates[i].getFullYear();
      var date_string = yyyy + "-" + mm + "-" + dd;
      finaloutput.push(date_string);
    }
  } else if (type == "Y" || type == "yearly") {
    // today = new Date(today.getFullYear(), 0, 1);
    // og_today = new Date(today.getFullYear(),11,31);

    var twoYearsOne = new Date(firstDate.getFullYear() - 2, 0, 1);
    var twoYearsTwo = new Date(firstDate.getFullYear() - 2, 11, 31);
    var oneYearsOne = new Date(firstDate.getFullYear() - 1, 0, 1);
    var oneYearsTwo = new Date(firstDate.getFullYear() - 1, 11, 31);

    var dates = [
      twoYearsOne,
      twoYearsTwo,
      oneYearsOne,
      oneYearsTwo,
      firstDate,
      secondDate,
    ];

    var i;
    for (i = 0; i < dates.length; i++) {
      var dd = String(dates[i].getDate()).padStart(2, "0");
      var mm = String(dates[i].getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = dates[i].getFullYear();
      var date_string = yyyy + "-" + mm + "-" + dd;
      finaloutput.push(date_string);
    }
  } else if (
    (type == "S" || type == "custom") &&
    start_date_og != null &&
    end_date_og != null
  ) {
    // const start_date = new Date(start_date_og + "T00:00:00")
    // const end_date = new Date(end_date_og)

    const today = new Date(start_date_og);
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();
    var todayDate = yyyy + "-" + mm + "-" + dd;

    const today2 = new Date(end_date_og);
    var dd = String(today2.getDate()).padStart(2, "0");
    var mm = String(today2.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today2.getFullYear();
    var today2Date = yyyy + "-" + mm + "-" + dd;

    var parts = todayDate.split("-");
    var start_date = new Date(parts[0], parts[1] - 1, parts[2]);

    var parts2 = today2Date.split("-");
    var end_date = new Date(parts2[0], parts2[1] - 1, parts2[2]);

    console.log(start_date);
    console.log(end_date);

    var firstyear = start_date.getFullYear();
    var firstmonth = start_date.getMonth();
    var firstday = start_date.getDate();

    var secondyear = end_date.getFullYear();
    var secondmonth = end_date.getMonth();
    var secondday = end_date.getDate();

    console.log(firstmonth);
    console.log(firstday);

    var twoYearsOne = new Date(firstyear - 2, firstmonth, firstday);
    var twoYearsTwo = new Date(secondyear - 2, secondmonth, secondday);
    var oneYearsOne = new Date(firstyear - 1, firstmonth, firstday);
    var oneYearsTwo = new Date(secondyear - 1, secondmonth, secondday);

    var dates = [twoYearsOne, twoYearsTwo, oneYearsOne, oneYearsTwo];

    var i;
    for (i = 0; i < dates.length; i++) {
      var dd = String(dates[i].getDate()).padStart(2, "0");
      var mm = String(dates[i].getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = dates[i].getFullYear();
      var date_string = yyyy + "-" + mm + "-" + dd;
      finaloutput.push(date_string);
    }
    finaloutput.push(todayDate);
    finaloutput.push(today2Date);
  }

  return finaloutput;
};

export const nFormatter = (num, digits, unit) => {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  var unit_temp = unit ? unit : "";
  var temp_digits;

  return (
    numberWithCommas((num / si[i].value).toFixed(digits)).replace(rx, "$1") +
    si[i].symbol +
    unit_temp
  );
};

export const titleWithFilter = (title, filter) => {
  var filterKey = "common.duration-filter." + filter;
  return title + " (" + i18n.t(filterKey) + ")";
};

export const axiosWithXAuth = (() => {
  const checkExistXAuth = () => {
    const xauth = window.localStorage.getItem('xauth');
    if (typeof xauth === 'undefined') {
      window.location.href = "/mobile/login2";
      return false;
    }
    return true;
  }

  const main = (config) => {
    if(checkExistXAuth()) {
      if(typeof config.headers === 'undefined') {
        config.headers = {};
      }
      const xauth = window.localStorage.getItem('xauth');
      config.headers.xauth = xauth;
      return new Promise((resolve, reject) => {
        axios(config).then((response) => {
          if (typeof response.headers.xauth !== 'undefined') {
            window.localStorage.setItem("xauth", response.headers.xauth);
          } else if (response) {
          }
          return resolve(response);
        }).catch((error) => {
          if (typeof error.response !== 'undefined'
            && typeof error.response.status !== 'undefined'
            && error.response.status === 403) {
            window.location.href = "/mobile/login2";
          }
          reject(error);
        });
      });
    }
  }

  const get = (...params) => {
    let config = {};
    config.method = 'get';
    if (params.length >= 1) {
      config.url = params[0];
    }
    if (params.length >= 2) {
      config = {...config, ...params[1]};
    }
    return main(config);
  }
  const post = (...params) => {
    let config = {};
    config.method = 'post';
    if (params.length >= 1) {
      config.url = params[0];
    }
    if (params.length >= 2) {
      config = {...config, ...params[1]};
    }
    return main(config);
  }

  const test = () => {
    console.log('helloooooo test');
  }
  main.test = test;
  main.get = get;
  main.post = post;
  return main;
})();

