import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Container, Spinner  } from 'react-bootstrap';

import { axiosWithXAuth } from "../../utils/functions";
import { API_URL} from '../../utils/api';
import { PAGES } from '../../constants';



const KPIPage = React.lazy(() => import("./AlarmRecordKPI"));
const RecordPage = React.lazy(() => import("./AlarmRecord"));



function AlarmBoard() {
  const {t} = useTranslation();
  
  var { buildings, sorted_buildings, selected_building, page} = useSelector(
    ({core, data}) => ({
        buildings: data.buildingList,
        sorted_buildings: data.sortedBuildingList,
        selected_building: data.selectedBuilding,
        page: data.page
    })
  )

  const loading = () => (
    <Container className="myLoadingContainer">
      <div style={{"position": "relative","top": "50%"}}>
      <Spinner animation="grow" role="status" size="sm"/>
      <span className="p-3">{t("common.loading")}</span>
      </div>
    </Container>
  );
  
  return (
    <div id="alarmBoard">
        <Suspense fallback={loading()}>

          {
            (page===PAGES.AlarmKPI)?
              <KPIPage />
            : <RecordPage />
          }
          
        </Suspense>
      
    </div>
  );
}

export default AlarmBoard;
