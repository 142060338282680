import React, {useState, useEffect, useRef} from 'react';
import { Container,ListGroup,Button,Form,Table,FloatingLabel,Alert,Row,Col} from 'react-bootstrap';
import { axiosWithXAuth } from "../../utils/functions";
import { API_URL} from '../../utils/api';

function AlarmManage(){

    const [buildings, setBuildings] = useState([]);
    const [online_alarm_list, setOnlineAlarms] = useState([]);
    const [resolved_alarm_list, setResolvedAlarms] = useState([]);

    const [create_alm_name, setCreateAlmName] = useState(undefined);
    const [create_desc, setCreateDesc] = useState("This is a testing alarm");
    const [create_asset_code, setCreateAssetCode] = useState("ACC");
    const [create_asset_num, setCreateAssetNum] = useState("01");
    const [create_fn_code, setCreateFnCode] = useState("TFALM");
    const [create_build_id, setCreateBuildId] = useState(28);
    const [create_build_code, setCreateBuildCode] = useState('GVFSL');
    const [create_level, setCreateLevel] = useState(4);
    const [create_system_group, setSystemGroup] = useState(4);

    const [showError, setShowError] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const [showSuccess, setShowSuccess] = useState(false);
    const [successMsg, setSuccessMsg] = useState("");

    useEffect(()=>{
        getBuildings();
        geResolveAlarmList();
        getDeleteAlarmList();
    }, []);

    useEffect(()=>{
        if (buildings.length>0 && create_build_id!== undefined){
            let building = buildings.find(b => b.id === create_build_id);
            setCreateBuildCode(building.code);
        }
    }, [create_build_id]);

    const getBuildings = () => {
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/getBuildings`
        })
        .then((response) => response.data)
        .then((data) => {
            let list = data
                        .sort((a,b) => {
                            if (a.r_id < b.r_id) return -1;
                            if (a.id < b.id) return -1;
                            return 1;
                        })
                        .map((building,i) => {
                            return {
                                id: parseInt(building.id),
                                code: building.building_code,
                                enName: building.building_name_en,
                                r_code: building.r_code,
                                r_id: building.r_id
                            };
                        });
            setBuildings(list);
        });
    }

    const geResolveAlarmList = () => {
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/getOnlineTestAlarms`
        })
        .then((response) => response.data)
        .then((data) => {
            setOnlineAlarms(data);
        });
    }

    const getDeleteAlarmList = () => {
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/GetResolvedAlarmRecords`
        })
        .then((response) => response.data)
        .then((data) => {
            setResolvedAlarms(data);
        });
    }

    const submitCreateAlarm = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            return;
        }
        event.preventDefault();

        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/createAlarmLog`,
            data:{
                "name" : create_alm_name,
                "desc" : create_desc,
                "asset_code" : create_asset_code,
                "asset_num" : create_asset_num,
                "fn_code" : create_fn_code,
                "b_id" : create_build_id,
                "b_code": create_build_code,
                "level" : create_level,
                "value" : 1,
                "system_group_id": create_system_group
            }
        })
        .then((response) => response.data)
        .then((data) => {
            showSuccessMsg("Success");
            setCreateAlmName(undefined);
        })
        .catch((error)=>{
            if (error.response === undefined){
                return;
            }
            if (error.response.data.errCode !== undefined){
                if (error.response.data.errCode !== 99){
                    showErrorMsg(error.response.data.errMsg);
                    return;
                }
            }
            showErrorMsg();
        });

    }

    const submitResolveAlarm = (alarm) =>{

        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/createAlarmLog`,
            data:{
                "name" : alarm.name,
                "desc" : "This is a testing alarm",
                "asset_code" : alarm.asset_code,
                "asset_num" : alarm.asset_no,
                "fn_code" : "TFALM",
                "b_id" : alarm.building_id,
                "b_code": alarm.b_code,
                "level" : alarm.priority,
                "value" : 0,
                "system_group_id": alarm.system_group_id
            }
        })
        .then((response) => response.data)
        .then((data) => {
            showSuccessMsg("Success");
            geResolveAlarmList();
        })
        .catch((error)=>{
            if (error.response === undefined){
                return;
            }
            if (error.response.data.errCode !== undefined){
                if (error.response.data.errCode !== 99){
                    showErrorMsg(error.response.data.errMsg);
                    return;
                }
            }
            showErrorMsg();
        });

    }

    const submitDeleteAlarm = (alm_re) =>{
        axiosWithXAuth({
            method: "post",
            url: `${API_URL}/api/clinic/admin/deleteAlarmRecord`,
            data:{
                "alm_re_id" : alm_re.id
            }
        })
        .then((response) => response.data)
        .then((data) => {
            showSuccessMsg("Success");
            getDeleteAlarmList();
        })
        .catch((error)=>{
            if (error.response === undefined){
                return;
            }
            if (error.response.data.errCode !== undefined){
                if (error.response.data.errCode !== 99){
                    showErrorMsg(error.response.data.errMsg);
                    return;
                }
            }
            showErrorMsg();
        });
    }

    const showSuccessMsg=(msg)=>{
        setSuccessMsg("");
        if (msg!==null)
            setSuccessMsg(msg);
        setShowSuccess(true);
        const timer = setTimeout(() => {
            setShowSuccess(false);
            setSuccessMsg("");
        }, 2000);
        return () => clearTimeout(timer);
    }

    const showErrorMsg=(msg)=>{
        setErrMsg("");
        if (msg!==null)
            setErrMsg(msg);
        setShowError(true);
        const timer = setTimeout(() => {
            setShowError(false);
            setErrMsg("");
        }, 2000);
        return () => clearTimeout(timer);
    }


    const renderCreateAlarmFrom = () => {
        return (
            <Form onSubmit={(e) => submitCreateAlarm(e)}>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_almname" label="Alarm Name (Eg. TEST-ALARM-URGENT) *Better to include 'TEST'" className="mb-3">
                                <Form.Control required type="text"  minLength="10" maxLength="50" value={create_alm_name?? ""} onChange={(e)=>setCreateAlmName(e.target.value)} />
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_level" label="Alarm Level" className="mb-3">
                                <Form.Select value={create_level} onChange={(e)=>setCreateLevel(parseInt(e.target.value))} >
                                    <option value="4">Emergency</option>
                                    <option value="3">Urgent</option>
                                    <option value="2">Non-Urgent</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_desc" label="Description (store in MongoDB only)" className="mb-3">
                                <Form.Control required type="text" maxLength="50" value={create_desc?? ""} onChange={(e)=>setCreateDesc(e.target.value)} />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_system_group" label="System Group" className="mb-3">
                                <Form.Select value={create_system_group} onChange={(e)=>setSystemGroup(parseInt(e.target.value))} >
                                    <option value="1">LAE</option>
                                    <option value="3">EMG</option>
                                    <option value="4">HVAC</option>
                                    <option value="7">FS</option>
                                    <option value="19">EL</option>
                                    <option value="30">DTL</option>
                                </Form.Select>
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_asset_code" label="Asset Code (Eg. ACC)" className="mb-3">
                                <Form.Control required type="text" maxLength="10" value={create_asset_code?? ""} onChange={(e)=>setCreateAssetCode(e.target.value)} />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_asset_num" label="Asset Number (Eg. 01)" className="mb-3">
                                <Form.Control required type="text" maxLength="10" value={create_asset_num?? ""} onChange={(e)=>setCreateAssetNum(e.target.value)} />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <FloatingLabel controlId="form_fn_code" label="Function Code (Eg. TFALM)" className="mb-3">
                                <Form.Control required type="text" maxLength="10" value={create_fn_code?? ""} onChange={(e)=>setCreateFnCode(e.target.value)} />
                            </FloatingLabel>
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group className="mb-3">
                    <FloatingLabel controlId="form_building" label="Building" className="mb-3">
                        <Form.Select value={create_build_id} onChange={(e)=>setCreateBuildId(parseInt(e.target.value))} >
                            {
                                buildings.map((building, i)=>{
                                    return (
                                        <option key={`building_${building.id}`}  value={building.id}>{building.r_code} | {building.id} - {building.code} - {building.enName}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </FloatingLabel>
                </Form.Group>
                <Button variant="primary" type="submit" className='float-start'>
                    Submit
                </Button>
            </Form>
        )
    }

    const renderResolveAlarmForm = () => {
        return (
            <Table striped bordered hover className="text-center">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Level</th>
                        <th>System</th>
                        <th>On Date</th>
                        <th>Off Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        online_alarm_list.map((online_alm,i)=>{
                            return (
                                <tr key={`online_${online_alm['id']}`}>
                                    <td>{online_alm['name']}</td>
                                    <td>{online_alm['priority']==2?"Non-Urgent":online_alm['priority']==3?"Urgent":online_alm['priority']==4?"Emergency":"---"}</td>
                                    <td>{online_alm['system_group_id']}</td>
                                    <td>{online_alm['on_dt']}</td>
                                    <td>{online_alm['off_dt']}</td>
                                    <td>{online_alm['status']}</td>
                                    <td>
                                        {
                                            (online_alm['status']===1)?
                                            <Button variant="primary" type="submit" onClick={()=>submitResolveAlarm(online_alm)}>
                                                Resolve
                                            </Button>
                                            :"Wait for 5min to resolve"
                                        }
                                    
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    const renderDeleteAlarmForm = () => {
        return (
            <Table striped bordered hover className="text-center">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Level</th>
                        <th>System</th>
                        <th>On Date</th>
                        <th>Off Date</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        resolved_alarm_list.map((resolved_alm,i)=>{
                            return (
                                <tr key={`resolved_${resolved_alm['id']}`}>
                                    <td>{resolved_alm['name']}</td>
                                    <td>{resolved_alm['priority']==2?"Non-Urgent":resolved_alm['priority']==3?"Urgent":resolved_alm['priority']==4?"Emergency":"---"}</td>
                                    <td>{resolved_alm['system_group_id']}</td>
                                    <td>{resolved_alm['on_dt']}</td>
                                    <td>{resolved_alm['off_dt']}</td>
                                    <td>
                                        {
                                            (resolved_alm['id']===1892||resolved_alm['id']===1902)?
                                            "For Notice Test"
                                            :<Button variant="primary" type="submit" onClick={()=>submitDeleteAlarm(resolved_alm)}>
                                                Delete
                                            </Button>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
        )
    }

    return (
        <Container className='pt-5'>
            <Alert variant="danger" onClose={() => setShowError(false)} dismissible show={showError} className="myMsgBar">
                {errMsg}
            </Alert>
            <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible show={showSuccess} className="myMsgBar">
                {successMsg}
            </Alert>
            <ListGroup>
                <ListGroup.Item>
                    <div className='fs-3 fw-bold'>Create Alarm</div>
                    {renderCreateAlarmFrom()}
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='fs-3 fw-bold'>Resolve Alarm
                        <Button variant="dark" size="sm" onClick={()=>geResolveAlarmList()} className='float-end'>Refresh</Button>
                    </div>
                    {renderResolveAlarmForm()}
                </ListGroup.Item>
                <ListGroup.Item>
                    <div className='fs-3 fw-bold'>Delete Alarm Record
                        <Button variant="dark" size="sm" onClick={()=>getDeleteAlarmList()} className='float-end'>Refresh</Button>
                    </div>
                    {renderDeleteAlarmForm()}
                </ListGroup.Item>

            </ListGroup>
        </Container>
    )
}

export default AlarmManage