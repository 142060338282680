import { dataConstant,PAGES,LEVELS } from "../constants";

const initState = {
    buildingList: [],
    sortedBuildingList: [],
    selectedBuilding: null,
    clusterList:[],
    selectedCluster: null,
    page: "AlarmKPI",
    level: null
};

const dataReducer = (state = initState, action) => {
    switch (action.type) {
        case dataConstant.type.SET_BUILDING_LIST:
            return {
                ...state,
                buildingList: action.buildingList
            };
        case dataConstant.type.UPDATE_BUILDING_LIST:
            if (action.buildingList.length===1)
                return {
                    ...state,
                    sortedBuildingList: action.buildingList,
                    selectedBuilding: action.buildingList[0].building_code
                }
            else
                return {
                    ...state,
                    sortedBuildingList: action.buildingList
                };
        case dataConstant.type.CHANGE_BUILDING:
            return {
                ...state,
                selectedBuilding: action.building !== null? action.building.building_code : null
            };
        case dataConstant.type.SET_CLUSTER_LIST:
            return {
                ...state,
                clusterList: action.clusterList
            };
        case dataConstant.type.CHANGE_CLUSTER:
            return {
                ...state,
                selectedCluster: action.cluster
            };
        case dataConstant.type.CHANGE_PAGE:
            return {
                ...state,
                page: PAGES[action.page]
            };
        case dataConstant.type.CHANGE_LEVEL:
            return {
                ...state,
                level: LEVELS[action.level]
            };
        default:
            return state;
    }
};

export default dataReducer;